import * as actionTypes from "../actions/types.js";
export default (state = { formList: [],myformList:[],submissionformList:[]}, action) => {
    
  switch (action.type) {
    case actionTypes.FORM_LIST:
      return {
        ...state,
        formList: action.payload ? action.payload : [],
      };
    case actionTypes.MYFORM_LIST:
      return {
        ...state,
        myformList: action.payload ? action.payload : [],
      };
    case actionTypes.SUBMISSION_FORM_LIST:
      return {
        ...state,
        submissionformList: action.payload ? action.payload : [],
      };
   
    default:
      return state;
  }
};
